:root {
  --main-color: #3A3A3A;
  --main-font-family: "Inter", Roboto, Arial, sans-serif;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  height: 100%;
  color: #3A3A3A;
  letter-spacing: -0.3px;
  font-size: 18px;
  min-width: 350px;
  font-family: "Inter", Roboto, Arial, sans-serif;
}

body.theme4 {
  font-family: "Calibri", Roboto, Arial, sans-serif;
}

* {
  font-family: "Inter", Roboto, Arial, sans-serif;
}
b {
  font-weight: bold !important;
}
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

a:focus,
a:active,
a:visited,
button:focus,
button:active,
[role="link"],
[role="button"],
[data-url] {
  outline: 0;
  outline: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.bounce {
  position: relative;
  transition: 0.125ms;
  animation: bounce .6s linear infinite;
}

.zoom {
  position: relative;
  transition: 0.125ms;
  animation: zoom .7s linear infinite;
}

@keyframes bounce {
  0% {transform: translateY(2px);}
  10% {transform: translateY(-4px);}
  30% {transform: translateY(-6px);}
  50% {transform: translateY(-8px);}
  80% {transform: translateY(-6px);}
  100% {transform: translateY(-2px);}
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
    /* equals 105% */
  }
  100% {
    transform: scale(1);
  }
}

.btn-animated:after {
  content: "";
  animation: moveInBottom .7s ease-in-out infinite;
  animation-fill-mode: backwards;
  height: inherit;
  position: absolute;
  border-radius: 50px;
  width: 100%;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    box-shadow:0 0 0 0 #feca0a
  }
  50% {
    opacity: 1;
    box-shadow: 0 0 16px 6px #feca0a
  }
  100% {
    opacity: 0;
    box-shadow:0 0 0 0 #feca0a
  }
}
